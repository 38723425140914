import React from 'react'
import cv from '../img/cv.pdf'
import { BsFillCloudDownloadFill } from 'react-icons/bs'

const About = () => {
  return (
    <div className='container' id='about'>
        <section className='about__content'>
            <div className='about__messages'>
            <div className='about__message'>
                <h3>Qui suis-je ?</h3>
                <div className='hero__message'>
                    <p>Bonjour ! Je me prénomme Axel Fouquet j'ai actuellement 20 ans, développeur web depuis officiellement 2 ans. Officiellement car tout débute à mes 10 ans, mes premiers scripts et également mes premières lignes de code JAVA. Depuis mes 14 ans je suis auto-didacte, ce loisir est devenu une passion.</p>
                </div>
                </div>
                <div className='about__message'>
                    <h4>Pourquoi me choisir ?</h4>
                    <div className='hero__message hero__message__second'>
                        <p>Voici quelques raisons, je suis quelqu'un de très curieux, determiné, j'adore le travail d'équipe.</p>
                        <p>Je ne vais pas vous gâcher toute la surprise du CV ! Vous pouvez donc le télécharger ci-dessous.</p>
                    </div>
                </div>
                </div>
            <div className='hero__cta about__cv'>
                <a href={cv} download><BsFillCloudDownloadFill/>Je télécharge</a>
            </div>
        </section>
    </div>
  )
}

export default About