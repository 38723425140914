import React, { useState } from 'react'
import Profil from '../img/profil.jpg'
import { AiFillHome, AiFillBook } from 'react-icons/ai'
import { RiFileUserFill } from 'react-icons/ri'
import { MdContactPage } from 'react-icons/md'

const Navbar = () => {

    const [color, setColor] = useState(false)
    const changeColor = () => {
        if(window.scrollY >= 10){
            setColor(true)
        }else{
            setColor(false)
        }
    }

    window.addEventListener('scroll', changeColor);

  return (
    <div className={color ? 'header header-bg-scroll' : 'header'} id="header">
        <nav className='nav container'>
            <a href='/' className='nav__logo'>Axel</a>

            <div className={color ? 'nav__menu header-bg-scroll' : 'nav__menu'} id='nav-menu'>
                <ul className='nav__list'>
                    <li className='nav__item'>
                        <a href='/' className='nav__link'>
                            <AiFillHome className='nav__icon'/>
                            <span className='nav__name'>Home</span>
                        </a>
                    </li>

                    <li className='nav__item'>
                        <a href='#about' className='nav__link'>
                            <RiFileUserFill className='nav__icon'/>
                            <span className='nav__name'>About</span>
                        </a>
                    </li>

                    <li className='nav__item'>
                        <a href='#skills' className='nav__link'>
                            <AiFillBook className='nav__icon'/>
                            <span className='nav__name'>Skills</span>
                        </a>
                    </li>

                    <li className='nav__item'>
                        <a href='#contact' className='nav__link'>
                            <MdContactPage className='nav__icon'/>
                            <span className='nav__name'>Contact</span>
                        </a>
                    </li>
                </ul>
            </div>

            <img className='nav__img' src= {Profil} alt='Fouquet Axel Profil'></img>
        </nav>
    </div>
  )
}

export default Navbar