import React from 'react'
import { AiFillGithub, AiFillLinkedin } from 'react-icons/ai'


const Footer = () => {
  return (
    <section className="footer">
      <div className='container'>

        <div className="foot-2">
            <p>©2023 - Fouquet Axel</p>
            <div className="socials">
                <a href="https://github.com/axelfqt" target='_blank' rel="noopener noreferrer"><AiFillGithub/></a>
                <a href="https://www.linkedin.com/in/axel-fouquet-646905221/" target='_blank' rel="noopener noreferrer"><AiFillLinkedin/></a>
            </div>
        </div>
      </div>
  </section>
    )}

export default Footer