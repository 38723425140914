import React from 'react'
import { BsSpeedometer } from 'react-icons/bs'
import { MdBiotech, MdOutlineDesignServices } from 'react-icons/md'

const Skills = () => {
  return (
    <div className='container' id='skills'>
        <section className='skills_hero' id='skills_hero'>
            <div className='skills__intro'>
                <h2>Mes compétences dans le domaine</h2>
            </div>
            <div className='skills__items'>
                <div className='item'>
                    <div className='head'>
                        <h3><BsSpeedometer/>Développement web</h3>
                    </div>
                    <p>Conception de sites Internet et applications web. Respect des standards du web et intégration de services tiers.</p>
                </div>
                <div className='item'>
                    <div className='head'>
                        <h3><MdBiotech/>Veille technologique</h3>
                    </div>
                    <p>Suivi et recherche constante de produits et services afin de rester au faits des futurs standards de l'industrie.</p>
                </div>
                <div className='item'>
                    <div className='head'>
                        <h3><MdOutlineDesignServices/>Design d'interfaces</h3>
                    </div>
                    <p>Conception de maquettes et design systems adaptés aux usages des utilisateurs finaux.</p>
                </div>
            </div>
        </section>
    </div>
  )
}

export default Skills