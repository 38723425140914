import React from 'react';
import Navbar from "./components/Navbar";
import './styles/styles.css';
import Home from './components/Home';
import Skills from './components/Skills';
import Footer from './components/Footer';
import SkillsBar from './components/SkillsBar';
import Contact from './components/Contact';
import ScrollToTop from './components/ScrollToTop';
import About from './components/About';

function App() {
  return (
    <div className="App">
      <ScrollToTop/>
      <Navbar/>
      <Home/>
      <About/>
      <Skills/>
      <SkillsBar/>
      <Contact/>
      <Footer/>
    </div>
  );
}

export default App;
