import React from 'react';
import { GrMail } from 'react-icons/gr';
import { BsFillTelephoneFill } from 'react-icons/bs'

const Home = () => {
  return (
    <div className='container' id='home'>
        <section className='home__hero' id='hero'>
            <div className='hero__content'>
                <h1>
                    <span>Bonjour, je m'appelle Axel Fouquet. <br/></span>
                    <span>Je suis <strong className="hero__gradient">Développeur web Full-Stack</strong> <br/></span>
                    <span>basé à Saint-quentin.</span>
                </h1>
                <div className='hero__message'>
                    <p>Vous avez une idée, un projet ou une entreprise que vous souhaitez développer en ligne ? Faites confiance à un développeur web passionné et expérimenté pour transformer votre vision en réalité numérique.</p>
                </div>
                <div className='hero__cta'>
                    <a href='mailto:axel.fouquet@3wa.io'><GrMail/>axel.fouquet@3wa.io</a>
                    <a href='tel:0619727567'><BsFillTelephoneFill/>06.19.72.75.67</a>
                </div>
                <div className='hero__social'>
                    
                </div>
            </div>
        </section>
    </div>
  )
}

export default Home