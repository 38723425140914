import React from 'react'
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const SkillsBar = () => {
    const skills = [
        {
            type: 'Symfony',
            pourcentage: 95
        },
        {
            type: 'Node',
            pourcentage: 75
        },
        {
            type: 'React',
            pourcentage: 85
        },
        {
            type: 'CMS',
            pourcentage: 90
        }
    ]

  return (
    <>
    <div className='container' id='skillsBar'>
        <div className='skill__box' style={{ stroke: 'var(--text-color)', fill: 'red'}}>
            {skills.map((skill, i) => {
                return (
                    <CircularProgressbar value={skill.pourcentage} text={skill.type} strokeWidth={3} key={i} />
                )
            })}
        </div>

    </div>
    </>
  )
}

export default SkillsBar