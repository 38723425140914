import React, { useEffect, useState } from 'react'
import { FaAngleUp } from 'react-icons/fa';


const ScrollToTop = () => {
    const [showTopBtn, setShowTopBtn] = useState(false);
    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 100) {
                setShowTopBtn(true);
            } else {
                setShowTopBtn(false);
            }
        });
    }, []);


  return (
        <div className={showTopBtn ? "top-to-btm" : "top-to-btm-hide"}>
            <FaAngleUp className="icon-position icon-style" onClick={goToTop}/>
        </div>
    
  )
}

export default ScrollToTop