import React from 'react'
import { AiFillGithub, AiFillLinkedin, AiFillMail, AiFillPhone } from 'react-icons/ai'

const Contact = () => {
  return (
    <div className='container' id='contact'>
        <section className='contact__content'>
            <h5>Vous souhaitez me contacter ?</h5>
            <div className='hero__message'>
                <p>Pour me contacter, rien de plus simple, vous pouvez m'envoyer un mail, un message sur linkedin ou directement m'appeler je vous répondrais au plus vite et le plus sérieusement possible.</p>
            </div>
            <div className='contact__social'>
                <a href="https://github.com/axelfqt" target='_blank' rel="noopener noreferrer"><AiFillGithub/></a>
                <a href="https://www.linkedin.com/in/axel-fouquet-646905221/" target='_blank' rel="noopener noreferrer"><AiFillLinkedin/></a>
                <a href="mailto:axel.fouquet@3wa.io"><AiFillMail/></a>
                <a href='tel:0619727567'><AiFillPhone/></a>
            </div>
        </section>
    </div>
  )
}

export default Contact